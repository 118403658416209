import React from "react";
import "./Modules.css";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import Modal from "react-modal";
// import Modal2 from "react-modal";

import { toast } from "react-hot-toast";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../Firebase/Firebase";
import { useEffect } from "react";
import { useState } from "react";

const Modules = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function openModal2() {
    setIsOpen2(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }
  function afterOpenModal2() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  function closeModal2() {
    setIsOpen2(false);
  }

  const notify = () => toast.success("Successfully Add");

  function firstclick() {
    notify();
  }

  function addmodule() {
    notify();
  }
  function del3() {
    notify1();
  }
  const notify1 = () => toast.success("Delete Successfully ");

  const [chapter, setChapter] = useState([]);
  const [chapter2, setChapter2] = useState([]);


  useEffect(() => {
    getDoc(doc(db, "ContestPrepAcademy", "Module1")).then((docSnap) => {
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        // setChapter(Object.entries(docSnap.data().Module1))
        setChapter(Object.values(docSnap.data().Module1).sort(function(a,b) {
         return a.chapter - b.chapter
        }));
      } else {
        console.log("No such document!");
      }
    });
    getDoc(doc(db, "ContestPrepAcademy", "Module2")).then((docSnap) => {
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        // setChapter(Object.entries(docSnap.data().Module1))
        setChapter2(Object.values(docSnap.data().Module2).sort(function(a,b) {
         return a.chapter - b.chapter
        }));
      } else {
        console.log("No such document!");
      }
    });
  }, []);
  // console.log(chapter.sort(function(a,b) {
  //   return a.chapter - b.chapter
  // }))

  const addDoc = async () => {
    const frankDocRef = doc(db, "ContestPrepAcademy", "Module2");

    // To update age and favorite color:
    await updateDoc(frankDocRef, {
      "Module2.Chapter1": {
        name: "FUNDAMENTALS",
        Eurl: "",
        Hurl: "",
        chapter: "1",
        PDF: "",
      },
      "Module2.Chapter2": {
        name: "PROTEIN",
        Eurl: "",
        Hurl: "",
        chapter: "2",
        PDF: "",
        bonus: "",
      },
      "Module2.Chapter3": {
        name: "FATS",
        Eurl: "",
        Hurl: "",
        chapter: "3",
        PDF: "",
      
      },
      "Module2.Chapter4": {
        name: "CARBS",
        Eurl: "",
        Hurl: "",
        chapter: "4",
        PDF: "",
      },
      "Module2.Chapter5": {
        name: "WATER AND ELECTROLYTES",
        Eurl: "",
        Hurl: "",
        chapter: "5",
        PDF: "",
      },
      "Module2.Chapter6": {
        name: "GUT HEALTH",
        Eurl: "",
        Hurl: "",
        chapter: "6",
        PDF: "",
      },
    }).then(() => {
      toast.success("added");
    });
  };

  const navigate = useNavigate();
  return (
    <div className="bg-module">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="module-div">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y modules11 ">
              <div className="module-a4">
                <div className="module-a5">
                  <div className="module-a7"></div>

                  <div>
                    <div className="sec-a1-2">
                      <div>
                        <h1 className="module-h5">MODULES CONTENT</h1>
                      </div>
                      <div className="add-ll">
                        <button
                          className="add-lesson-btn"
                          onClick={() => addDoc()}
                        >
                          Add
                        </button>

                        <button className="add-lesson-btn" onClick={openModal2}>
                          Add Module
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="sec-a1-1">
                        <div>
                          <h1 className="module-h6">MODULE 1 - INTRODUCTION</h1>
                        </div>
                        <div className="btns-c1">
                          <button className="add-lesson-btn" onClick={del3}>
                            Delete Module
                          </button>

                          <button
                            className="add-lesson-btn"
                            onClick={openModal}
                          >
                            Add Lesson
                          </button>
                        </div>
                      </div>
                      <div className="modules-title">
                        {Array.isArray(chapter)
                          ? chapter.map((chapter) => {
                              return (
                                <>
                                  <div className="mod-c1">
                                    <div
                                      onClick={() =>
                                        navigate("/Modules/Lessons", {
                                          state: {
                                            id: `Chapter${chapter.chapter}`,
                                            data: chapter
                                          },
                                        })
                                      }
                                      className="module-c13"
                                    >
                                      <div>
                                        <h1 className="module-h8">
                                          {chapter.name}
                                        </h1>
                                      </div>
                                    </div>
                                    <div className=" add-b1a ">
                                      <button
                                        className="add-lesson-btn"
                                        onClick={del3}
                                      >
                                        Edit
                                      </button>

                                      <button
                                        className="add-lesson-btn"
                                        onClick={del3}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          : null}
                      </div>
                    </div>
                    <div>
                      <div className="sec-a1-1">
                        <div>
                          <h1 className="module-h6">MODULE 2 - NUTRITION PART A</h1>
                        </div>
                        <div className="btns-c1">
                          <button className="add-lesson-btn" onClick={del3}>
                            Delete Module
                          </button>

                          <button
                            className="add-lesson-btn"
                            onClick={openModal}
                          >
                            Add Lesson
                          </button>
                        </div>
                      </div>
                      <div className="modules-title">
                        {Array.isArray(chapter2)
                          ? chapter2.map((chapter) => {
                              return (
                                <>
                                  <div className="mod-c1">
                                    <div
                                      onClick={() =>
                                        navigate("/Modules/Lessons", {
                                          state: {
                                            id: `Chapter${chapter.chapter}`,
                                            data: chapter
                                          },
                                        })
                                      }
                                      className="module-c13"
                                    >
                                      <div>
                                        <h1 className="module-h8">
                                          {chapter.name}
                                        </h1>
                                      </div>
                                    </div>
                                    <div className=" add-b1a ">
                                      <button
                                        className="add-lesson-btn"
                                        onClick={del3}
                                      >
                                        Edit
                                      </button>

                                      <button
                                        className="add-lesson-btn"
                                        onClick={del3}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-a2">
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="modal-content-h"
        >
          <div className="header-modal-a1">
            <div>
              <h1 className="home-ah1">Add Lesson</h1>
            </div>
            <div className="svg-close-modal">
              <AiOutlineClose onClick={closeModal} />
            </div>
          </div>
          <hr />
          <div className="form-data-a1">
            {/* <form>
             */}
            <div>
              <input
                type="text"
                className="input-a2 "
                placeholder="Lesson Name"
                name=""
                id=""
              />
            </div>
            <div>
              <input
                type="text"
                className="input-a3"
                placeholder="Serial number"
                name=""
                id=""
              />
            </div>

            <div className="check-box-post-btn">
              <button onClick={firstclick} className="add-btn-1">
                Add
              </button>
            </div>
            {/* </form> */}
          </div>
        </Modal>
        <Modal
          isOpen={modalIsOpen2}
          onAfterOpen={afterOpenModal2}
          onRequestClose={closeModal2}
          contentLabel="Example Modal"
          className="modal-content-h"
        >
          <div className="header-modal-a1">
            <div>
              <h1 className="home-ah1">Add Module</h1>
            </div>
            <div className="svg-close-modal">
              <AiOutlineClose onClick={closeModal2} />
            </div>
          </div>
          <hr />
          <div className="form-data-a1">
            {/* <form>
             */}
            <div>
              <input
                type="text"
                className="input-a2 "
                placeholder="Module Name"
                name=""
                id=""
              />
            </div>
            <div>
              <input
                type="text"
                className="input-a3"
                placeholder="Serial number"
                name=""
                id=""
              />
            </div>

            <div className="check-box-post-btn">
              <button onClick={addmodule} className="add-btn-1">
                Add
              </button>
            </div>
            {/* </form> */}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Modules;
