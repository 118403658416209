/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import "../../Features/Sidebar/Sidenav.scss";
import "./Studydetails.css";
import {  useLocation } from "react-router-dom";

import {  db} from "../../Firebase/Firebase";


import { Tab, Tabs, TabList, TabPanel } from "react-tabs";


import "../../Features/Sidebar/Sidebar.css";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import {AiOutlineClose } from "react-icons/ai";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";


import { MdPlayLesson } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { getDoc, doc } from "firebase/firestore";
import { toast } from "react-hot-toast";


import Modal from "react-modal";







const Studydetails = () => {

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {

    subtitle.style.color = "#f00";
  }
  
function firstclick () {
  notify()
}
const notify = () => toast.success('Successfully Add');

  const location = useLocation();
  const data = location.state.data

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };





  const [chapterEnglish, setChapterEnglish] = useState();
  const [chapterHindi, setChapterHindi] = useState();
  const [chapterPDF, setChapterPDF] = useState();
 


  useEffect(() => {
    getDoc(
      doc(db, "ContestPrepAcademy", "Module1", "English", location.state.id)
    ).then((docSnap) => {
      if (docSnap.exists()) {
        setChapterEnglish(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });


    getDoc(
      doc(db, "ContestPrepAcademy", "Module1", "Hindi", location.state.id)
    ).then((docSnap) => {
      if (docSnap.exists()) {
        setChapterHindi(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });


    getDoc(
      doc(db, "ContestPrepAcademy", "Module1", "PDF", location.state.id)
    ).then((docSnap) => {
      if (docSnap.exists()) {
        setChapterPDF(docSnap.data());
      } else {
        console.log("No such document!");
      }
    });

   


  }, []);







  










  const [toggleState2, setToggleState2] = useState(1);
  const toggleTab2 = (index) => {
    setToggleState2(index);
  };



  return (
    <div className="bg-study">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="fle-main-div">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y ">
              <div className="tabs-1">
         

                <div>
                  <div className="paf-g tab-panel-i">
                    <div className="tab-oi">
                      <div>
                        <h1 className="intro-o">MODULE 1 - INTRODUCTION</h1>
                      </div>
                   
                      <div>
                        <div>
                          <Tabs>
                            <div>
                              <TabList className="tab-list-modules">
                                <Tab
                                  onClick={() => toggleTab(1)}
                                  className={toggleState === 1 ? "a1" : "a2"}
                                >
                                  <span className="span-m1">
                                    <MdPlayLesson size={25} />
                                    Lesson
                                  </span>
                                </Tab>
                                <Tab
                                  onClick={() => toggleTab(2)}
                                  className={toggleState === 2 ? "a1" : "a2"}
                                >
                                  <span className="span-m2">
                                    <TbListDetails size={25} />
                                    Materials
                                  </span>
                                </Tab>
                              </TabList>
                              <hr />
                              
                            </div>
                            
                            <div >

<div className="update-url">
  <button className="url-btn-up"  onClick={openModal}>Update Url</button>
</div>
                              
                              <TabPanel className="tab-panel-m1">

                              <Tabs>
                              
                            <TabList>
                              <div>
                              </div>
                              <div style={{marginBottom: '30px'}} className="dash-7">
                                <Tab>
                                  <button
                                    onClick={() => toggleTab2(1)}
                                    className={
                                      toggleState2 === 1
                                        ? "dash-btn-1"
                                        : "dash-btn1"
                                    }
                                  >
                                    English
                                  </button>
                                </Tab>
                                <Tab>
                                  <button
                                    onClick={() => toggleTab2(2)}
                                    className={
                                      toggleState2 === 2
                                        ? "dash-btn-2"
                                        : "dash-btn2"
                                    }
                                  >
                                    Hindi
                                  </button>
                                </Tab>
                              </div>
                            </TabList>
                            {/* Intro Video English */}

                            <TabPanel>
                            
                                  <iframe
                                    src={data?.Eurl}
                                    style={{border: 0 , height: '360px' , width: '640px' , maxWidth: '100%',borderRadius:'15px'}}
                                   
                                    allowFullScreen="true"
                                    allow="encrypted-media"
                                    title="ContestPrep"
                                  ></iframe>
                              
                            </TabPanel>
                            {/* Intro Video Hindi */}

                            <TabPanel>
                             
                                  <iframe
                                    src={data?.Hurl}
                                    style={{border: 0 , height: '360px' , width: '640px' , maxWidth: '100%',borderRadius:'15px'}}
                                   
                                    allowFullScreen="true"
                                    allow="encrypted-media"
                                    title="ContestPrep"
                                  ></iframe>
                               
                            </TabPanel>
                          </Tabs>
                                <div className="i-a3">
                         
                              
                                </div>
                              </TabPanel>
                            </div>
                            <div className="tab-panel-2">
                              <TabPanel>
                                <div className="MaterialsLesson">
                                  <p>Click Below to Open the Desired PDF</p>
                                    <a target="_blank" style={{marginTop: '25px'}} className="button-24" href={data?.PDF}>Download PDF</a>
                                </div>
                              </TabPanel>
                            </div>
                          </Tabs>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

            
              </div>

            </div>
          </div>
        </div>
      </div>

      <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="modal-content-h"
        >
          <div className="header-modal-a1">
          <div>
              <h1 className="home-ah1">Add Url & File</h1>
            </div>
            <div className="svg-close-modal">
              <AiOutlineClose onClick={closeModal} />
            </div>
           
          </div>
          <hr />
          <div className="form-data-a1">

          {/* <form>
           */}
            <div>

            <input type="text" className="input-a2 " placeholder="English Url" name="" id="" />
            </div>
            <div>

            <input type="text" className="input-a3"  placeholder="Hindi Url" name="" id="" />
            </div>
        
      <div>

  <label htmlFor="">Add Pdf</label>
<div className="file-a2">
        <input type="file" name="" id="" />
</div>
            <div className="check-box-post-btn">
              <button onClick={firstclick} className="add-btn-1">Add</button>
            </div>
      </div>
          {/* </form> */}
          </div>

        </Modal>

    </div>
  );
};

export default Studydetails;
