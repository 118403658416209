import React, { useEffect, useState } from "react";
import "./User.css";
import { MdDone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import Sidebar from "../../Features/Sidebar/Sidebar1";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../Firebase/Firebase";
import { RxCross1 } from "react-icons/rx";
import { useMemo } from "react";

const User = () => {
  const navigate = useNavigate();

  const [user, setuser] = useState();

  useEffect(() => {
    const q = query(collection(db, "usersLesson"), orderBy("name", "asc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setuser(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [search, setSearch] = useState("");

  const countries = useMemo(() => {
    if (!search) return user;

    return user.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, user]);

  return (
    <div className="bg-user">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="sidebar-home-d">
            <Sidebar />
          </div>
          <div className="main-y">
            <div className="head-use">
              <h1 className="us-h1">Users Details</h1>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <span>Total Users = </span>
              <span>{user?.length}</span>
            </div>
            <div
              style={{
                padding: "10px 0px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "60%" }}>
                <form>
                  <input
                    placeholder="Search"
                    type="search"
                    className="search-input-forums"
                    name="search"
                    id="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </form>
              </div>
            </div>
            <div class="container">
              <table class="responsive-table">
                <thead>
                  <tr>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Payment Type</th>
                    <th scope="col">Payment Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {Array.isArray(user)
                    ? countries.map((item, key) => {
                        return (
                          <>
                            <tr>
                              <th scope="row">
                                <div className="user-profile-n">
                                  <span>
                                    <FaUserCircle size={30} />
                                  </span>

                                  {item.name}
                                </div>
                              </th>
                              <td data-title="Email">{item.email}</td>
                              <td data-title="Payment Type">OTP</td>
                              <td
                                className="pay-status"
                                data-title="Payment Status"
                                data-type="currency"
                              >
                                {item.loginPermission === true ? (
                                  <MdDone size={30} />
                                ) : (
                                  <RxCross1 size={30} />
                                )}
                              </td>
                              <td data-title="Action" data-type="currency">
                                <button
                                  onClick={() =>
                                    navigate(`/Users/${item.id}`, {
                                      state: { user: item },
                                    })
                                  }
                                  className="view-btn-2"
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : null}

                  {/* <tr>
                    <th scope="row">

                    <div className="user-profile-n">

<span>
<FaUserCircle
size={30}

/>
</span>
User 2
</div>
                    </th>
                    <td data-title="Email">user@gmail.com</td>
                    <td data-title="Payment Type">EMI</td>
                    <td className="pay-status"  data-title="Payment Status" data-type="currency">
                      <div className="payment-status">
                        <div className="cl1">
                            <span>1ST EMI</span>
                          <MdDone size={20} />
                        </div>
                        <div className="cl1">

                        <span>2ND EMI</span>

                          <MdDone size={20} />
                        </div>
                        <div className="cl1">

                        <span>3RD EMI</span>

                          <AiOutlineClose size={20} />
                        </div>
                      </div>
                    </td>
                    <td data-title="Action" data-type="currency">
                    <button className="view-btn-2"
                  onClick={()=>navigate('/Users/Course')}

                    >View</button>
                    
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
