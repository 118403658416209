import React, { useState } from "react";
import {
  SLink,
  SLinkContainer,
  SLinkIcon,
  SLinkLabel,
  SLinkNotification,
  SLogo,
  SSidebar,
  SSidebarButton,
} from "./styles";
import "./Side.css";
// import { logoSVG } from "../../assets";
import { HiUsers } from "react-icons/hi";

import { BiSupport } from "react-icons/bi";

import {
  BsCameraVideoFill,
  BsChatSquareText,
  BsLayoutSplit,
} from "react-icons/bs";
import { ImBooks } from "react-icons/im";

import { useLocation } from "react-router-dom";
import { IoMdContacts } from "react-icons/io";
import { AiFillHome } from "react-icons/ai";

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  return (
    <div className="side-c">
      <SSidebar isOpen={sidebarOpen}>
        <>
          <SSidebarButton
            isOpen={sidebarOpen}
            onClick={() => setSidebarOpen((p) => !p)}
          >
            <BsLayoutSplit />
          </SSidebarButton>
        </>
        <SLogo>{/* <img src={logoSVG} alt="logo" /> */}</SLogo>

        {linksArray.map(({ icon, label, notification, to }) => (
          <div class={"tooltip"}>
            <SLinkContainer key={label} isActive={pathname === to}>
              <SLink
                to={to}
                style={!sidebarOpen ? { width: `fit-content` } : {}}
              >
                <SLinkIcon isActive={pathname === to}>{icon}</SLinkIcon>
                {sidebarOpen && (
                  <>
                    <SLinkLabel isActive={pathname === to}>{label}</SLinkLabel>
                    {/* if notifications are at 0 or null, do not display */}
                    {!!notification && (
                      <SLinkNotification>{notification}</SLinkNotification>
                    )}
                  </>
                )}
              </SLink>
            </SLinkContainer>
            {/* <SLinkIcon isActive={pathname === to}>{icon}</SLinkIcon>  */}
            <div class="right">
              <div class="text-content">
                <h3 style={{ whiteSpace: "pre" }}>
                  <SLinkLabel isActive={pathname === to}>{label}</SLinkLabel>
                </h3>
              </div>
              <i></i>
            </div>
          </div>
        ))}

        {/* <SDivider />
      {secondaryLinksArray.map(({ icon, label }) => (
<div class={ "tooltip" }>
        
        <SLinkContainer key={label}>
          <SLink to={"/login"} style={!sidebarOpen ? { width: `fit-content` } : {}}>
            <SLinkIcon>{icon}</SLinkIcon>
            {sidebarOpen && <SLinkLabel>{label}</SLinkLabel>}

            <div class="right">  
        <div class="text-content">
        <h3  style={{whiteSpace:'pre'}}>     
        {label}
      
</h3>

        </div>
        <i></i>

        </div>

          </SLink>
        </SLinkContainer>
      </div>
      ))}

      <SDivider /> */}
      </SSidebar>
    </div>
  );
};

const linksArray = [
  {
    label: "HOME",
    icon: <AiFillHome />,
    to: "/",
    notification: 0,
  },
  {
    label: "USER",
    icon: <HiUsers />,
    to: "/User",
    notification: 0,
  },

  {
    label: "STUDY DETAILS",
    icon: <ImBooks />,
    to: "/Course",
    notification: 0,
  },
  {
    label: "FORUMS",
    icon: <BsChatSquareText />,
    to: "/Forums",
    notification: 0,
  },

  {
    label: "VLOG",
    icon: <BsCameraVideoFill />,
    to: "/Vlog",
    notification: 0,
  },

  {
    label: "CONTACT US",
    icon: <IoMdContacts />,
    to: "/Contactus",
    notification: 0,
  },
  {
    label: "SUPPORT",
    icon: <BiSupport />,
    to: "/Support",
    notification: 0,
  },
];
// const secondaryLinksArray = [
//   {
//     label: "Logout",
//     icon: <MdLogout />,
//     to: "/logout",

//   },

// ];

export default Sidebar;
