import React, { useEffect, useState } from "react";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import "./Replydata.css";
import { toast } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { 
  collection,
  deleteDoc,
  onSnapshot,
  orderBy,
  query,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Firebase/Firebase";
import { CiCircleRemove } from "react-icons/ci";   
import {FcApprove} from 'react-icons/fc' 

const Replydata = () => { 
  const { id } = useParams(); 
  const location = useLocation();

  const user = location.state.user;

  async function deletereplyClick(id) {
    await deleteDoc(doc(db, "Forum", user.id, "reply", id)).then(() => {
      toast.success("Reply Delete");
    });
  }


  async function deletdiscussionClick() {
    await deleteDoc(doc(db, "Forum", user.id )).then(() => {
      toast.success("Forum Deleted successfully");

    });
  }

  async function accept() {
    const washingtonRef = doc(db, "Forum", user.id);
    await updateDoc(washingtonRef, {
      verified: "approved",
    }).then(() => {
      toast.success("Approved");
    });
  }
  async function decline() {
    const washingtonRef = doc(db, "Forum", user.id);
    await updateDoc(washingtonRef, {
      verified: "declined",
    }).then(() => {
      toast.error("Declined");
    });
  }

  const [open, setOpen] = useState(false);
  const [forumdata, setForumData] = useState({});

  useEffect(() => {
    const q = query(
      collection(db, "Forum", id, "reply"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setForumData(cities);
      }
    );

    return () => {
      unsubscribe();
    };
  },
  [id]);

const [forum1 , setForum1] = useState();

useEffect(() => {
  const unsub = onSnapshot(doc(db, "Forum", user.id), (doc) => {
    setForum1({id: doc.id,...doc.data()});

});


return () => unsub()
},[user.id])

  return (
    <div className="reply-bg">
      <div className="body-fix-h ">
        <div className="wrapper ">
          <div className="side-reply">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y  grid-c1 ">
              <div className="data-reply  grid-c2 ">
                <div className="reply-data-s  ">
                  <div className="reply-main-data   ">
                    <div className="border-reply ">
                      <div>
                        <div className="head-reply-comment">
                          <div className="btn-d-a1">
                            <div class="acc-dec-btn">
                              {forum1?.verified === "declined" ? (

                          
                              
                              <button className="acc-btn1">
                              <CiCircleRemove size={20}/>  Declined
                              </button>
                                  ) : (
<>
{forum1?.verified === "approved" ? (

                          
                              
<button className="acc-btn2">
<FcApprove size={20}/>  Approved
</button>
    ) : (
<>
<button onClick={accept} className="acc-btn">
                                Accept
                              </button>
                              <button onClick={decline} className="dec-btn">
                                Decline
                              </button>

</>

    )}
</>

                                  )}
                          
                            </div>
                            <div>
                              <button
                                onClick={deletdiscussionClick}
                                className="delete-btn-rr"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                          <div>
                            <h1 className="reply-h1">{user.name}</h1>
                            <div className="span-2-reply">
                              <span>Posted by</span>
                              <span className="name-hov"> {user.user}</span>
                              <span> on</span>
                              <span>
                                {" "}
                                {moment(user?.createdAt).format(
                                  "MMMM Do YYYY , h:mm:ss a"
                                )}{" "}
                              </span>
                            </div>
                            <div className="para-1-reply">
                              <p>{user.description}</p>
                            </div>
                            {user?.lastreply === "0" ? (
                              <></>
                            ) : (
                              <div className="span-2-reply">
                                <span className="name-hov">Last Reply</span>
                                <span>
                                  {" "}
                                  {moment(user?.lastreply).fromNow()}
                                </span>
                              </div>
                            )}
                            {user?.Image ? (
                              <div className="forumimg">
                                <img
                                  onClick={() => setOpen(true)}
                                  className="forumimgimg"
                                  src={user?.Image}
                                  alt=""
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="reply-count reply-btn-count">
                        <h1>{user?.replies} Replies</h1>
                      </div>
                      <hr />
                      {forumdata.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          {Array.isArray(forumdata)
                            ? forumdata.map((item, key) => {
                                return (
                                  <>
                                    <div className="another-reply-1">
                                      <div className="user-dp-time-member">
                                        <div>
                                          {item?.profileimg === "" ? (
                                            <img
                                              className="user-reply-dp"
                                              src={require("../../../assests/Img/user.png")}
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              className="user-reply-dp"
                                              src={item?.profileimg}
                                              alt=""
                                            />
                                          )}
                                        </div>
                                        <div className="user-data-t">
                                          <h1>{item?.user}</h1>
                                          <p>
                                            {moment(item?.createdAt).format(
                                              "MMMM Do YYYY , h:mm:ss a"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="section-reply-p2">
                                        <p className="reply-para-uu">
                                          {item?.reply}
                                        </p>
                                      </div>
                                      <div style={{ marginTop: "10px" }}>
                                        <button
                                          onClick={() =>
                                            deletereplyClick(item.id)
                                          }
                                          className="delete-btn-rr"
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                );
                              })
                            : null}
                        </>
                      )}
                    </div>
                    <div className="btn-side"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={[{ src: user?.Image }]}
        />
      </div>
    </div>
  );
};

export default Replydata;
