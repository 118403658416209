
import React from "react";

import { useNavigate } from "react-router-dom";
import Sidebar1 from "../../Features/Sidebar/Sidebar1";
import { AiOutlineClose } from "react-icons/ai";

import Modal from "react-modal";
import "./Home.css";

import { toast } from "react-hot-toast";
import { BsFillCameraFill } from "react-icons/bs";

const Home = () => {


  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
   const notify = () => toast.success('Successfully Add');

function firstclick () {
  notify()
}




function closeModal2() {
  setIsOpen2(false);
}
function openModal2() {
  setIsOpen2(true);
}
function afterOpenModal2() {

  subtitle.style.color = "#f00";
}


const notify2 = () => toast.success('Change Successfully');

function editcourse () {
  notify2()
}



  const navigate = useNavigate();

  return (
    <div className="bg-hh">
      <div className="body-fix-h">
        <div className="wrapper ">
          <div className="home-side-bar-data">
            <div className="sidebar-home-d">
              <Sidebar1 />
            </div>
            <div className="main-y  ">
              <div className="data-div-h ">
                <div className="data-container">
               
                  <div>
                    <div className="h1-courses">
                      <h1>COURSES</h1>
                    </div>
                    <div className="modal-open-b1">
                      <button className="add-course-btn" onClick={openModal}>
                        Add Course
                      </button>
                    </div>
                  </div>
                  <div></div>
                  <div className="card-head-course">
                    <div className="card-head-data-j">
                      <div>
                        <img
                          className="ww"
                          src={require("../../../assests/Img/Artboard2.jpg")}
                          alt=""
                          onClick={() => navigate("/Modules")}
                        />
                      </div>
                      <div className="card-h-certifi">
                        <h1>Contest Prep Certification</h1>
                      </div>

                      <div className="course-edit-del">
                        <button
                  onClick={openModal2}
                          className="edit-a1"
                        >
                          Edit
                        </button>
                    
                        <button
                          onClick={() => navigate("/Modules")}
                        
                        className="module-btn1">Modules</button>
                      </div>
                 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-a1">
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="modal-content-h"
        >
          <div className="header-modal-a1">
          <div>
              <h1 className="home-ah1">Add course</h1>
            </div>
            <div className="svg-close-modal">
              <AiOutlineClose onClick={closeModal} />
            </div>
           
          </div>
          <hr />
          <div className="form-data-a1">

          {/* <form> */}
            
         
            <div className="change-course-img"> 
              
<div class="profile-pic">
  <label class="-label" for="file">
    <span class="glyphicon glyphicon-camera"></span>
    <span><BsFillCameraFill
    size={30}
    /></span>
  </label>
  <input id="file" type="file" onchange="loadFile(event)"/>

  <img  src={require('../../../assests/Img/add img.png')} width="200" alt=""/>
</div>
            </div>
            <div className="latest-up-a1">
              <input
                placeholder="Course Name"
                type="text"
                className="input-a1"
              />
            </div>
            <div className="check-box-post-btn">
              <button onClick={firstclick} className="change-btn-dis">Add</button>
            </div>

          </div>

        </Modal>
        <Modal
          isOpen={modalIsOpen2}
          onAfterOpen={afterOpenModal2}
          onRequestClose={closeModal2}
          contentLabel="Example Modal"
          className="modal-content-h"
        >
          <div className="header-modal-a1">
          <div>
              <h1 className="home-ah1">Edit Course</h1>
            </div>
            <div className="svg-close-modal">
              <AiOutlineClose onClick={closeModal2} />
            </div>
           
          </div>
          <hr />
          <div className="form-data-a1">

          {/* <form>
           */}
                      <div className="change-course-img"> 
              
              <div class="profile-pic">
                <label class="-label" for="file">
                  <span class="glyphicon glyphicon-camera"></span>
                  <span><BsFillCameraFill
                  size={30}
                  /></span>
                </label>
                <input id="file" type="file" onchange="loadFile(event)"/>
              
                <img  src={require('../../../assests/Img/add img.png')} width="200" alt=""/>
              </div>
                          </div>
            <div>

            <input type="text" className="input-a2 " placeholder="Course Name" name="" id="" />
            </div>
        

       
            <div className="check-box-post-btn">
              <button
              onClick={editcourse}
              className="add-btn-1">Change</button>
            </div>
          {/* </form> */}
          </div>

        </Modal>
      </div>
    </div>
  );
};

export default Home;
