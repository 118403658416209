import React from "react";
import {
  RouterProvider,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import './App.css'
import './Style.scss'

import { router } from "./Components/Routes/Route/Route";
const App = () => {
  return (
    <>

        <Toaster />

    <RouterProvider router={router}></RouterProvider>


    </>
  );
};

export default App;
